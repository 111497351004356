/// @name reset
/// @group Meta
/// @author Eric Meyer
///CSS RESETS
html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    // font: inherit;
    vertical-align: baseline;
  }
  article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {
    display: block;
  }
  body {
    line-height: 1;
  }
  ol, ul {
    list-style: none;
  }
  blockquote, q {
    quotes: none;
  }
  blockquote:before, blockquote:after, q:before, q:after {
    content: '';
    content: none;
  }
  table {
    border-collapse: collapse;
    border-spacing: 0;
  }
  * {
    box-sizing: border-box;
  }
  body {
    margin: 0;
    width: 100%;
    height: 100vh;
    background-color: transparent;
  }
  * {
    -webkit-overflow-scrolling: touch;
  }
  .background {
    background: url("../assets/images/slant1.svg");
    background-size: cover;
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-attachment: fixed;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    overflow: scroll;
  }
  .screen-mask-status {
    width: 24px;
      height: 24px;
      border-radius: 100%;
      background: #027E79;
      display: inline-block;
      top: 160px;
      z-index: 1;
      vertical-align: top;
      position: relative;
      @media only screen and (max-width: 900px) {
        top: 210px;
        opacity: 0;
      }
  }
  .screen-mask-wrapper {
      z-index: 1;
      position: fixed;
      left: 650px;
      @media only screen and (max-width: 1270px) {
        left: 50%;
        top: 4%;
      }
      @media only screen and (max-width: 900px) {
        opacity: 0;
      }
  }
  .screen-info {
    height: 350px;
    background: #2E2E2E;
    font-family: 'HelveticaNeue-Bold';
    color: #ffffff;
    text-align: center;
    padding-top: 120px;
    border-radius: 6px;
    @media only screen and (max-width: 1200px) {
      height: 214px;
      padding-top: 0;
    }
  
    h1 {
      font-size: 40px;
      @media only screen and (max-width: 1200px) {
        padding-top: 19%;
        font-size: 25px;
      }
    }
    h2 {
      font-size: 30px;
      margin-top: 10px;
      @media only screen and (max-width: 1200px) {
        font-size: 20px;
      }
    }
  
    .contact-icons {
      margin-top: 30px;
      a {
        display: inline-block;
      }
      @media only screen and (max-width: 1200px) {
        margin-top: 10px;
      }
      &__linkedin, &__github, &__twitter, &__email {
        svg{
          height: 40px;
          width: 40px;
          display: inline-block;
          vertical-align: middle;
          margin-right: 15px;
          transition: background .3s;
          &:hover {
            path {
              transition: fill .3s ease;
              fill: #027e79;
            }
          }
          @media only screen and (max-width: 1200px) {
            margin-right: 5px;
          }
        }
      }
      .simona {
        background: url("../assets/images/pixelated2x.png");
        background-size: 75px;
        height: 75px;
        width: 75px;
        display: inline-block;
        background-repeat: no-repeat;
        vertical-align: middle;
        margin-right: 15px;
        @media only screen and (max-width: 1200px) {
          background-size: 50px;
          height: 50px;
          width: 50px;
        }
      }
    }
  }
  .screen-mask {
      z-index: 1;
      width: 100%;
      transition: z-index 0.1s linear;
      perspective: 1200px;
      perspective-origin: 80% 80%;
      margin-left: 50px;
      display: inline-block;
      position: relative;
  
      &__image-wrapper {
        transition: all 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
        transform: rotateX(2deg) rotateY(-14deg) rotate(1deg) translateX(-10%);
        box-shadow: 10px 20px 80px rgba(0,0,0,0.2);
        width: 600px;
        border-radius: 6px;
        @media only screen and (max-width: 1200px) {
          width: 360px;
        }
        @media only screen and (max-width: 900px) {
          transition: none;
          transform: none;
        }
        &--block {
          height: 348px;
          @media only screen and (max-width: 1200px) {
            height: 208px;
          }
          a.view-project {
            position: absolute;
            right: 0px;
            top: -20px;
            color: #231f20;
            display: inline-block;
            font-weight: bold;
            font-size: 16px;
            font-family: Helvetica Neue,"nimbus-sans",Helvetica,Arial,sans-serif;
            line-height: 1;
            text-decoration: none;
            transition: color .3s;
            padding-left: 22px;
            @media only screen and (max-width: 1200px) {
              font-size: 10px;
            }
            @media only screen and (max-width: 900px) {
              right: 20%;
            }
          }
          a.view-project:before {
              background-image: url("../assets/images/external_link_grey_dk.svg");
              background-size: cover;
              background-repeat: no-repeat;
              content: '';
              display: block;
              height: 22.624px;
              left: 0;
              position: absolute;
              top: 0;
              transition: background .3s;
              width: 16px;
          }
          a.view-project:after {
              background: #231f20;
              content: '';
              left: 0;
              height: 2.828px;
              position: absolute;
              top: -2.828px;
              transition: background .3s;
              width: 100%;
          }
          a.view-project:hover:before {
              background-image: url("../assets/images/external_link_yellow.svg");
          }
          a.view-project:hover:after {
              background: #f1c40f;
          }
        }
        img {
          width: 100%;
          border-radius: 6px;
          @media only screen and (max-width: 900px) {
          }
        }
      }
  }
  .main-block {
    width: 630px;
    height: auto;
    background: #ffffff;
    position: relative;
    top: 70px;
    left: 100px;
    box-shadow: 0 0 0 1px rgba(0,0,0,0.10), 0 2px 4px 0 rgba(0,0,0,0.10);
    border-radius: 7px;
    font-family: 'Georgia';
    font-size: 23px;
    line-height: 33px;
    // min-width: 360px;
    @media only screen and (max-width: 1270px) {
      width: 50%;
      left: 5%;
    }
    @media only screen and (max-width: 900px) {
      width: 90%;
      // top: 200px;
    }
  
    &__frameworks-container {
      display: inline-block;
      font-family: 'HelveticaNeue';
      background: #333333;
      margin-bottom: 5px;
      color: white;
      font-weight: bold;
      border-radius: 4px;
      @media only screen and (max-width: 1270px) {
        padding: 2px;
      }
      @media only screen and (max-width: 425px) {
        line-height: 20px;
      }
      div {
        display: inline-block;
        font-size: 14px;
        vertical-align: middle;
        padding: 5px;
        @media only screen and (max-width: 425px) {
          font-size: 12px;
        }
      }
    }
  
    span {
      margin-bottom: 30px;
      margin-top: 0;
      display: inline;
      padding: 5px 0;
      line-height: 1.7;
      @media only screen and (max-width: 1270px) {
        font-size: 20px;
      }
      @media only screen and (max-width: 425px) {
        font-size: 14px;
        margin-bottom: 15px;
        line-height: 1.9;
      }
    }
    &__inner-wrapper {
      margin-left: 50px;
      margin-right: 130px;
      margin-bottom: 430px;
      @media only screen and (max-width: 1270px) {
        margin-right: 100px;
      }
      @media only screen and (max-width: 900px) {
        margin-right: 50px;
      }
    }
    &__icon-wrapper {
        position: absolute;
      .speech-bubble {
        position: relative;
        background: #027E79;
        color: #fff;
        top: 60px;
        left: 94px;
        padding: 7px;
        width: 86px;
        font-size: 16px;
        display: none;
        font-family: 'HelveticaNeue';
        transition:0.3s ease all;
        box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1), 0 2px 4px 0 rgba(0, 0, 0, 0.1);
        &:before {
          content: '';
          border-style: solid;
          border-width: 12px 15px 1px 0;
          border-color: transparent #027E79 transparent transparent;
          position: absolute;
          left: -15px;
        }
      }
      &__profile-image {
        position: absolute;
        margin-top: 50px;
        overflow: hidden;
        height: 70px;
        width: 70px;
        img {
          height: 70px;
          width: 70px;
        }
      }
    }
    &__intro {
      &--wrapper {
        padding-top: 160px
      }
    }
    &__left-half {
      display: inline-block;
    }
    &__right-half {
      display: inline-block;
    }
    &__fca, &__salesforce {
      &--header {
        margin-top: 50px;
      }
    }
    &__intro, &__salesforce, &__movie-vue, &__contact, &__remote-dir, &__fca, &__marketing, &__daily-nclex {
      margin-bottom: 30px;
      color: #333333;
      @media only screen and (max-width: 425px) {
        margin-bottom: 20px;
      }
        &--header{
          span {
            color: #333333 !important;
            font-weight: bold;
            font-size: 34px !important;
            margin-top: 20px;
            margin-bottom: 28px;
            font-family: 'HelveticaNeue-Bold';
  
            @media only screen and (max-width: 1270px) {
              font-size: 24px !important;
            }
            @media only screen and (max-width: 425px) {
              font-size: 18px !important;
            }
          }
        }
        &--header span a {
          color: #333333 !important;
          font-weight: bold;
          font-size: 24px !important;
          margin-top: 20px;
          margin-bottom: 28px;
          font-family: 'HelveticaNeue-Bold';
          text-decoration: none;
          @media only screen and (max-width: 425px) {
            font-size: 18px !important;
          }
          &::after {
            background-image: url("../assets/images/external_link_grey_dk.svg");
            background-size: cover;
            background-repeat: no-repeat;
            content: '';
            display: inline-block;
            height: 24px;
            position: relative;
            transition: background .3s;
            width: 24px;
            right: 0;
            margin-left: 5px;
            top: 3px;
          }
          &::after:hover {
            background-image: url("../assets/images/external_link_yellow.svg") !important;
          }
        }
      }
      &__contact {
        &--end {
          padding-bottom: 50px;
        }
      }
      &__remote-dir {
        &--list-item {
          display: block !important;
          margin-bottom: 5px !important;
          font-size: 14px;
          margin-top: 5px !important;
          line-height: 1 !important;
          &:nth-child(2) {
            margin-top: 30px !important;
          }
        }
      }
  }
  .highlight-blue {
   background: rgba(2, 126, 121, 0.1);
   -webkit-transition:background-color 0.3s linear;
   -moz-transition: background-color 0.3s linear;
   -o-transition: background-color 0.3s linear;
  }
  .salesforce-dot {
    background: #00a1e0;
    -webkit-transition:background-color 0.1s linear;
    -moz-transition: background-color 0.1s linear;
    -o-transition: background-color 0.1s linear;
  }
  .highlight-salesforce {
    background: rgba(0, 161, 224, 0.1);
    -webkit-transition:background-color 0.3s linear;
    -moz-transition: background-color 0.3s linear;
    -o-transition: background-color 0.3s linear;
  }
  .blue-dot{
    background: #027E79;
    -webkit-transition:background-color 0.1s linear;
    -moz-transition: background-color 0.1s linear;
    -o-transition: background-color 0.1s linear;
  }
  .highlight-remote {
   background: rgba(255, 116, 123, 0.1);
   -webkit-transition:background-color 0.3s linear;
   -moz-transition: background-color 0.3s linear;
   -o-transition: background-color 0.3s linear;
  }
  .remote-dot {
    background: #FF747B;
    -webkit-transition:background-color 0.1s linear;
    -moz-transition: background-color 0.1s linear;
    -o-transition: background-color 0.1s linear;
  }
  .highlight-dailynclex {
   background: rgba(253, 224, 175, 0.2);
   -webkit-transition:background-color 0.3s linear;
   -moz-transition: background-color 0.3s linear;
   -o-transition: background-color 0.3s linear;
  }
  .dailynclex-dot {
    background: #FDE0AF;
    -webkit-transition:background-color 0.1s linear;
    -moz-transition: background-color 0.1s linear;
    -o-transition: background-color 0.1s linear;
  }
  
  .highlight-movie-vue {
   background: rgba(0, 162, 230, 0.1);
   -webkit-transition:background-color 0.3s linear;
   -moz-transition: background-color 0.3s linear;
   -o-transition: background-color 0.3s linear;
  }
  .movie-vue-dot {
    background: #00A2E6;
    -webkit-transition:background-color 0.1s linear;
    -moz-transition: background-color 0.1s linear;
    -o-transition: background-color 0.1s linear;
  }
  .highlight-fca {
    background: rgba(1, 103, 176, 0.1);
    -webkit-transition:background-color 0.3s linear;
    -moz-transition: background-color 0.3s linear;
    -o-transition: background-color 0.3s linear;
  }
  .fca-dot {
    background: #0067b0;
    -webkit-transition:background-color 0.1s linear;
    -moz-transition: background-color 0.1s linear;
    -o-transition: background-color 0.1s linear;
  }
  .highlight-marketing {
    background: rgba(249, 188, 250, 0.14);
    -webkit-transition:background-color 0.3s linear;
    -moz-transition: background-color 0.3s linear;
    -o-transition: background-color 0.3s linear;
  }
  .marketing-dot {
    background: #F9BCFA;
    -webkit-transition:background-color 0.1s linear;
    -moz-transition: background-color 0.1s linear;
    -o-transition: background-color 0.1s linear;
  }
  .highlight-contact {
    background: #FDF3E6;
    -webkit-transition:background-color 0.3s linear;
    -moz-transition: background-color 0.3s linear;
    -o-transition: background-color 0.3s linear;
  }
  .contact-dot {
    background: #f1c40f;
    -webkit-transition:background-color 0.1s linear;
    -moz-transition: background-color 0.1s linear;
    -o-transition: background-color 0.1s linear;
  }
  
  .show {
    display: inline-block !important;
    margin-bottom: 0 !important;
  }
  .hide {
    display: none !important;
  }
  
  .contact-info-small-screen {
    .contact-icons {
      margin-top: 30px;
      a {
        display: inline-block;
      }
      @media only screen and (max-width: 1200px) {
        margin-top: 10px;
      }
      &__linkedin, &__github, &__twitter, &__email {
        svg {
          height: 40px;
          width: 40px;
          display: inline-block;
          vertical-align: middle;
          margin-right: 15px;

          &:hover {
            path {
              transition: fill .3s ease;
              fill: #027e79;
            }
          }

          @media only screen and (max-width: 1200px) {
            margin-right: 5px;
          }
          @media only screen and (max-width: 425px) {
            width: 25px;
            height: 25px;
          }
        }
      }
      .simona {
        background: url("../assets/images/pixelated2x.png");
        background-size: 75px;
        height: 75px;
        width: 75px;
        display: inline-block;
        background-repeat: no-repeat;
        vertical-align: middle;
        margin-right: 15px;
        @media only screen and (max-width: 1200px) {
          background-size: 50px;
          height: 50px;
          width: 50px;
        }
        @media only screen and (max-width: 425px) {
          width: 40px;
          height: 40px;
          background-size: 40px;
          margin-right: 5px;
        }
      }
    }
  }
  .contact-button, .resume-mobile {
      text-decoration: none;
      cursor: pointer;
      color: #231f20;
      display: inline-block;
      font-weight: bold;
      font-size: 16px;
      font-family: Helvetica Neue,"nimbus-sans",Helvetica,Arial,sans-serif;
      border-bottom: 3px solid #231f20;
      padding-bottom: 4px;
      transition: border .3s;
      &:hover {
        border-bottom: 3px solid #F1C10F;
      }
  }
  
  .resume-mobile {
    margin-left: 10px;
  }
  